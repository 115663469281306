<template>
    <div class="allocation-page">
        <a-tabs v-model="activeKey">
           <template slot="tabBarExtraContent">
              <div class="filtrate" v-show="activeKey==1">
                  <a-input-search 
                      placeholder="请输入查询内容" 
                      @search="onSearch"
                      class="fr"
                  />
              </div>
            </template>
          <a-tab-pane key="1" tab="我的任务"></a-tab-pane>
          <a-tab-pane key="2" tab="所有任务" v-if="roleName.indexOf('组长')>-1"></a-tab-pane>
        </a-tabs>
        <a-table @change="PageChange" :pagination="pagination" :columns="columns" :rowKey="(r, i) => i" bordered :data-source="myJobList" v-show="activeKey==1"  style="padding:10px 20px 0">
            <template slot="iconInfo" slot-scope="text,record">
                <div style="display: flex;justify-content: space-between;align-items: baseline;">
                    <span @click="popProgress(record)" class="hoverColor">{{text}}</span>
                    <a-button type="link" class="fr"  @click="popup(record)"><a-icon style="fontSize:18px" type="info-circle" /></a-button>
                </div>
            </template>
            <template slot="action" slot-scope="text,record">
                <router-link v-if="record.jobTypeName=='审查'" target="_blank" :to="{path:'/inputs_data',query:{cubeId:record.cubeId,cubeName:encodeURI(record.cubeName),jobId:record.jobId,editable:2}}">开始工作</router-link>
                <router-link v-else-if="record.jobTypeName=='录入'" target="_blank" :to="{path:'/inputs_data',query:{cubeId:record.cubeId,cubeName:encodeURI(record.cubeName),jobId:record.jobId,editable:1}}">开始工作</router-link>
                <router-link v-else target="_blank" :to="{path:'/inputs_data',query:{cubeId:record.cubeId,cubeName:encodeURI(record.cubeName),jobId:record.jobId,editable:0}}">开始工作</router-link>
                <a-divider type="vertical" v-if="record.checkUserId===record.inputUserId" />
                <router-link v-if="record.checkUserId===record.inputUserId" :to="{path:'/inputs_data',query:{cubeId:record.cubeId,cubeName:encodeURI(record.cubeName),jobId:record.jobId,editable:2}}">去审核</router-link>
            </template>
        </a-table>
        <a-table :pagination="pagination2" :rowKey="(r, i) => i" :columns="allColumns" bordered @change="PageChange2" :data-source="allData" v-show="activeKey==2"  style="padding:10px 20px 0">
            <template slot="iconInfo" slot-scope="text,record">
                <div @click="popProgress(record)" class="hoverColor" style="display: flex;justify-content: space-between;align-items: baseline;">
                    <span >{{text}}</span>
                    <a-button type="link" class="fr"  @click.stop="popup(record)"><a-icon style="fontSize:18px" type="info-circle"/></a-button>
                </div>
            </template>
            <template slot="jobProgress" slot-scope="text">
              <div style="position:relative;">
                    <a-progress
                      :percent="Number(text)"
                      :strokeWidth="20"
                      :showInfo="false"
                      strokeColor="rgb(70,217,147)"
                    >
                    </a-progress>
                    <span style="color: #222;position:absolute;line-height:23px;left: 50%;transform: translateX(-50%);">{{text}}%</span>
              </div>
            </template>
        </a-table>
        <a-modal v-model="Visible" :footer="null" title="分配详情" centered>
          <div class="select">
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">选择小组</a-col>
                <a-col flex="300px">
                    <a-select
                      style="width: 100%"
                      placeholder="选择小组"
                      v-model="VisibleList.teamName"
                      disabled
                    >
                    </a-select>
                </a-col>
            </a-row>
            </div>
            <!-- 任务名称 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">任务名称</a-col>
                <a-col flex="300px">
                        <a-select
                        style="width: 100%"
                        placeholder="选择数据库"
                          v-model="VisibleList.jobName"
                          disabled
                        >
                        </a-select>
                </a-col>
            </a-row>
            </div>
            <!-- 分配工作 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">分配工作</a-col>
                <a-col flex="200px">
                    <a-select
                    style="width: 100%"
                      v-model="VisibleList.cubeName"
                      disabled
                    >
                    </a-select>
                </a-col>
                <a-col flex="220px">
                    <div style="width:220px">
                        <a-select
                        style="width: 100%"
                          :value="'已选地区：'+VisibleList.regionNames"
                          disabled
                        >
                        </a-select>
                    </div>
                </a-col>
                <a-col flex="220px">
                    <div style="width:220px">
                      <a-select
                        style="width: 100%"
                          :value="'已选时间：'+VisibleList.timeNames"
                          disabled
                        >
                        </a-select>
                    </div>
                </a-col>
            </a-row>
            </div>
            <!-- 时间区间 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">时间区间</a-col>
                <a-col flex="200px">
                    <a-date-picker disabled v-model="VisibleList.startDate" valueFormat="YYYY-MM-DD"/>
                </a-col>
                <a-col flex="200px">
                    <a-date-picker disabled v-model="VisibleList.endDate" valueFormat="YYYY-MM-DD"/>
                </a-col>
            </a-row>
            </div>
            <div class="btns">
              <a-button type="button" @click="Visible=!Visible" size="large">确定</a-button>
            </div>
        </div>
        </a-modal>
        <Progress ref="proChild"></Progress>
    </div>
</template>

<script>
// import { messageTips } from '../../until/tools';
import Progress from './components/modalProgress.vue'
import config from '@/until/configs';
import { getCookie } from '../../until/tools';
const progressList=[
  {
    name:'张力与',
    startDate:'2000-1-9',
    endDate:'2011-1-3',
    memo:"段白电脑bdijkan翻到卡我觉得那豌豆bdijkan翻到卡我觉得那豌豆bdijkan翻到卡我觉得那豌豆bdijkan翻到卡我觉得那豌豆bdijkan翻到卡我觉得那豌豆bdijkan翻到卡我觉得那豌豆bdijkan翻到卡我觉得那豌豆"
  },
  {
    name:'张力与',
    startDate:'2000-1-9',
    endDate:'2011-1-3',
    memo:"段白电脑bdijkan翻到卡我觉得那豌豆"
  },
  {
    name:'张力与',
    startDate:'2000-1-9',
    endDate:'2011-1-3',
    memo:"段白电脑bdijkan翻到卡我觉得那豌豆"
  },
  {
    name:'张力与',
    startDate:'2000-1-9',
    endDate:'2011-1-3',
    memo:"段白电脑bdijkan翻到卡我觉得那豌豆"
  },
  {
    name:'张力与',
    startDate:'2000-1-9',
    endDate:'2011-1-3',
    memo:"段白电脑bdijkan翻到卡我觉得那豌豆"
  },
  {
    name:'张力与',
    startDate:'2000-1-9',
    endDate:'2011-1-3',
    memo:"段白电脑bdijkan翻到卡我觉得那豌豆"
  },
]
const columns = [
{
    title: '序号',
    // dataIndex: 'sort',
    customRender: (text, record, index) => `${index + 1}`,
    key: '1',
    align:'center',
  },
  {
    title: '任务名称',
    dataIndex: 'jobName',
    key: '2',
    scopedSlots: { customRender: 'iconInfo'}
  },
  {
    title: '分配时间',
    dataIndex: 'createTime',
    key: '3',
  },
  {
    title: '开始时间',
    dataIndex: 'startDate',
    key: '4',
  },
  {
    title: '结束时间',
    dataIndex: 'endDate',
    key: '5',
  },
  {
    title: '工作类型',
    dataIndex: 'jobTypeName',
    key: '6',
  },
  {
    title: '当前状态',
    dataIndex: 'jobStatusName',
    key: '7',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: '8',
    align:'center',
    scopedSlots: {
      customRender: 'action',
    },
  },
];
const allColumns = [
{
    title: '序号',
    customRender: (text, record, index) => `${index + 1}`,
    key: '1',
    align:'center',
  },
  {
    title: '任务名称',
    dataIndex: 'jobName',
    key: '2',
    scopedSlots: { customRender: 'iconInfo'}
  },
  {
    title: '分配时间',
    dataIndex: 'createTime',
    key: '3',
  },
  {
    title: '开始时间',
    dataIndex: 'startDate',
    key: '4',
  },
  {
    title: '结束时间',
    dataIndex: 'endDate',
    key: '5',
  },
  {
    title: '负责小组',
    dataIndex: 'teamName',
    key: '6',
  },
  {
    title: '当前状态',
    dataIndex: 'jobStatusName',
    key: '7',
  },
  {
    title: '完成度',
    dataIndex: 'jobProgress',
    key: '8',
    scopedSlots: { customRender: 'jobProgress'}
  },
];
export default {
    components:{
      Progress,
    },
    data() {
        return {
            progressList,
            ops: config.vueScrollSetting,
            myJobList:[],
            columns,
            allColumns,
            allData:[],
            activeKey:"1",
            keyword:"",
            VisibleList:[],
            Visible:false,//详情弹框
            pagination: {
              total: 0,
              current:1,
              pageSize: 10,//每页中显示10条数据
              // showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
              showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
            },
            pagination2: {
              total: 0,
              current:1,
              pageSize: 10,//每页中显示10条数据
              pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
              showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
            },
            roleName:getCookie('roleName')
        }
    },
    methods:{
        //新增审核按钮
        toInputs_data2(record){
          this.$router.push({
            path:"/inputs_data",
            query:{
              cubeId:record.cubeId,
              cubeName:encodeURI(record.cubeName),
              jobId:record.jobId,
              editable:2
            }
          })
        },

        //翻页
        PageChange(pageCurrent){
          this.pagination.current=pageCurrent.current
          this.inputs_get_myJob(pageCurrent.current)
        },
        PageChange2(pageCurrent){
          this.pagination2.current=pageCurrent.current
          this.inputs_get_allTaskList(pageCurrent.current)
        },
        popup(record){
          this.Visible = true;
          this.VisibleList=record
        },
        popProgress(record){
          this.$refs.proChild.popProgress()
          this.$refs.proChild.progressList=record.logList
        },
        onSearch(value) {
          this.keyword = value
          this.inputs_get_myJob(1)
        },
        inputs_get_myJob(current){
          let params ={
            currentPage:current,
            jobName:this.keyword
          }
          this.$http.inputs_get_myJob(params).then(res=>{
            if(res.success) {
              this.pagination.total=res.page.allSize
              const data =res.list
              if(data!==undefined){
                const temp = data.map((item)=>{
                    return item
                })
                 this.myJobList=temp
              }else{
                this.myJobList=[]
              }
            }
          })
        },
        //获取所有任务
        inputs_get_allTaskList(page=1){
          this.$http.inputs_get_allTaskList({currentPage:page}).then(res=>{
            if(res.success){
              this.allData=res.list;
              this.pagination2.total = res.page.allSize;
            }
          })
        },
  },
  mounted() {
    this.inputs_get_myJob()
    this.inputs_get_allTaskList()
  }
    
}
</script>

<style lang="scss" scoped>
@import '../../scss/itemAllocation.scss';
</style>
